import React from 'react';
import { Flex, Grid } from 'antd';

const { useBreakpoint } = Grid;

function Footer() {
  const screens = useBreakpoint();
  const isMobile = screens.xs;

  return (
    <Flex
      vertical={isMobile}
      justify="space-between"
      align="center"
      className={
        isMobile ? 'xs:pt-[0px] xs:pb-[72px] xs:pr-[40px] xs:pl-[40px]' : ''
      }
      style={{
        backgroundColor: '#F8F9FA',
        marginTop: 'auto',
      }}
    >
      <Flex
        vertical
        style={{
          // marginLeft: isMobile ? null : '2%',
          padding: '24px 40px ',
        }}
        className="xs:!px-[20px] xs:!py-[24px]"
        align={isMobile ? 'center' : 'start'}
      >
        <h2
          style={{
            marginBottom: 0,
          }}
          className="text-[#333] font-[Urbanist] text-[1.33333rem] not-italic font-bold leading-[1.77778rem] "
        >
          A little love.
        </h2>
        <p className="text-[#999] font-[Urbanist] text-[0.88889rem] not-italic font-medium leading-[1.33333rem] m-0 [@media(max-width:576px)]:max-w-[80%] [@media(max-width:576px)]:text-center [@media(max-width:576px)]:pt-[8px]">
          A little love can make a big difference. Please share your feedback
          and make our day!
        </p>
      </Flex>
      <p
        style={{
          marginRight: isMobile ? null : '2%',
        }}
      >
        <span className="crafted-by">Crafted by</span>
        <span className="crafted-by-luwak">
          <a
            href="https://www.luwaktech.com/"
            title="LuwakTech"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            LuwakTech ❤️
          </a>
        </span>
      </p>
    </Flex>
  );
}

export default Footer;
