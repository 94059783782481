import React from 'react';
import { Flex, Row, Col } from 'antd';

// import mainsvg from '../images/content.svg';
// import RegisterForAccess from './RegisterForAccess';
import Timer from './Timer';
// import arrowsvg from '../images/arrow.svg';

// function ShowImage({ isMobile }) {
//   return (
//     <img
//       src={mainsvg}
//       alt="content"
//       style={{
//         width: isMobile ? '90%' : '60%',
//         height: 'auto',
//         maxWidth: isMobile ? '90%' : '70%',
//       }}
//     />
//   );
// }

// const { useBreakpoint } = Grid;

function Content() {
  // const screens = useBreakpoint();
  // const isMobile = screens.sm && !screens.md;

  return (
    <>
      <Row className="mb-[16px] xs:mt-[24px] sm:mt-[96px]">
        <Col xl={11} lg={14} md={17} sm={22} xs={22} className="mx-auto">
          <p className=" text-[#333] text-center font-[Urbanist] sm:text-[2.38889rem] xs:text-[1.3rem] not-italic font-bold sm:leading-[3.11111rem] xs:leading-[2.11111rem] ">
            Get 100 GB of free storage for iPhone and android devices!
          </p>
        </Col>
      </Row>
      <Row className="mb-[40px]">
        <Col xl={10} lg={12} md={16} sm={21} xs={21} className="mx-auto">
          <p className="text-[#4d4d4d] text-center font-[Urbanist] sm:text-[1rem] xs:text-[0.9rem] not-italic font-medium sm:leading-[1.77778rem] xs:leading-[1.4rem] ">
            Backup your Files, Photos and Videos at Fast, Secure and Encrypted
            storage at no cost. Alternative for Google Drive and iCloud.
          </p>
        </Col>
      </Row>

      <Flex
        vertical
        justify="flex-start"
        align="center"
        style={
          {
            // backgroundImage: isMobile ? '' : `url(${mainsvg})`,
            // // width: '100vw',
            // height: '100vh',
            // backgroundSize: 'auto',
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
          }
        }
        gap={32}
      >
        <Timer />

        {/* {isMobile && <RegisterForAccess />} */}
      </Flex>
    </>
  );
}

export default Content;
