import React from 'react';
import { Flex, Grid } from 'antd';

const { useBreakpoint } = Grid;
function Title() {
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  return (
    <Flex
      vertical={false}
      justify={isMobile ? 'center' : 'space-between'}
      align="center"
      className="px-[40px] py-[40px]"
    >
      <h2 className="text-[#333] font-[Urbanist] text-[1.77778rem] not-italic font-bold leading-[2.66667rem]">
        <a href="/" title="Luwak">
          Luwak.
        </a>
      </h2>
    </Flex>
  );
}

export default Title;
