import React, { useEffect } from 'react';
import { Flex } from 'antd';
import Countdown from 'react-countdown';

const deadline =
  (localStorage.getItem('timer') !== null
    ? JSON.parse(localStorage.getItem('timer'))
    : Date.now()) +
  1000 * 60 * 60 * 24 * 7; // Dayjs is also OK

function Completionist() {
  return <span>Time is over!</span>;
}
function CustomText({ value, text }) {
  useEffect(() => {
    if (localStorage.getItem('timer') === null) {
      localStorage.setItem('timer', JSON.stringify(Date.now()));
    }
  }, []);
  return (
    <Flex vertical justify="center" align="center" className="min-w-[48px]">
      <Flex
        vertical={false}
        justify="center"
        align="center"
        className="bg-[#F21300] p-[8px] text-white text-center font-[Urbanist] text-[1.77778rem] not-italic font-bold leading-[2.22222rem]"
        gap={16}
      >
        {String(value).padStart(2, '0')}
      </Flex>
      <div className="text-[#4D4D4D] text-center font-[Urbanist] text-[0.88889rem] not-italic font-bold leading-[1.77778rem]">
        {text}
      </div>
    </Flex>
  );
}
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  }

  return (
    <>
      <CustomText text="Days" value={days} />
      <CustomText text="Hours" value={hours} />
      <CustomText text="Mins" value={minutes} />
      <CustomText text="Sec" value={seconds} />
    </>
  );
};

function Timer() {
  return (
    <Flex vertical={false} wrap gap={16}>
      <Countdown date={deadline} renderer={renderer} />
    </Flex>
  );
}

export default Timer;
