import React from 'react';
import { Button } from 'antd';
import arrowSvg from '../images/arrow.svg';

function RegisterForAccess({ className }) {
  return (
    <Button
      type="primary"
      size="large"
      className={`xs:h-[56px] sm:h-[56px] md:h-[48px] px-[32px] py-[12px] !rounded-[2px] text-[#FFF] font-[Urbanist] text-[1.11111rem] not-italic font-bold leading-[1.77778rem] ${className}`}
      icon={<img src={arrowSvg} alt="arrow" />}
      iconPosition="end"
      // data-tally-open="w4jNY5"
      // data-tally-layout="modal"
      // data-tally-align-left="1"
      // data-tally-hide-title="1"
      // data-tally-auto-close="5000"

      data-tally-open="wk7AY6"
      data-tally-layout="modal"
      data-tally-align-left="1"
      data-tally-emoji-text="👋"
      data-tally-emoji-animation="wave"
      data-tally-auto-close="3000"
    >
      Register for Early Access
    </Button>
  );
}

export default RegisterForAccess;
