import React from 'react';
import { Flex } from 'antd';
import joinSvg from '../images/join.svg';
import RegisterForAccess from './RegisterForAccess';

function JoinList() {
  return (
    <div
      style={{
        backgroundColor: '#F8F9FA',
      }}
    >
      <Flex
        gap="middle"
        vertical={false}
        justify="space-evenly"
        align="center"
        wrap
      >
        <img
          src={joinSvg}
          alt="content"
          style={{
            width: '25%',

            maxWidth: '25%',
          }}
        />
        <div>
          <p className="text-[#333] font-[Urbanist] text-[2.38889rem] not-italic font-bold leading-[3.11111rem]">
            Join the Waiting List
          </p>
          <p className="text-[#4d4d4d] font-[Urbanist] text-[1rem] not-italic font-medium leading-[1.77778rem]">
            We are onboarding members from the waitlist each week, so look out
            for an email with your invite soon!
          </p>
          <RegisterForAccess />
        </div>
      </Flex>
    </div>
  );
}

export default JoinList;
