import React from 'react';
import { Flex, Grid } from 'antd';
import Title from './Title';
import RegisterForAccess from './RegisterForAccess';

const { useBreakpoint } = Grid;

function Header() {
  const screens = useBreakpoint();
  const isMobile = screens.xs;

  return (
    <Flex
      vertical={false}
      justify={isMobile ? 'center' : 'space-between'}
      align="center"
    >
      <Title />
      {!isMobile ? (
        <Flex
          vertical={false}
          justify="space-between"
          align="center"
          className="px-[40px] py-[40px]"
        >
          <RegisterForAccess />
        </Flex>
      ) : (
        <Flex
          vertical
          justify="center"
          align="center"
          className="w-full fixed z-[1] left-0 bottom-0"
        >
          <RegisterForAccess className="w-full" />
        </Flex>
      )}
    </Flex>
  );
}

export default Header;
