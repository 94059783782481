import React from 'react';
import { Col, Row, Grid } from 'antd';
import { Header, Content, Footer } from './components';
import mainsvg from './images/content.svg';
import smSvg from './images/content_sm.svg';

const { useBreakpoint } = Grid;

function App() {
  const screens = useBreakpoint();

  const isMobile = (screens.xs || screens.sm || screens.md) && !screens.lg;
  console.log('screens', screens, isMobile);
  return (
    <Row
      className="w-full mx-auto xs:bg-[position:50%_55%] sm:bg-[position:50%_68%] "
      style={{
        backgroundImage: isMobile ? `url(${smSvg})` : `url(${mainsvg})`,
        backgroundSize: 'auto',
        // backgroundPosition: '50% 68%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Col span={24}>
        <Header />
      </Col>
      <Col span={24} className="h-[80vh]">
        <Content />
      </Col>
      <Col span={24}>
        <Footer />
      </Col>
    </Row>
  );
}

export default App;
